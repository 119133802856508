import React from 'react'
import { Link } from 'react-router-dom'
import { whatsAppIcon } from '../assets'

const WhatsAppStick = ({phoneNumber , message}) => {

    const handleClick = () => {
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
      };
  return (
    <div className='whatsapp-btn-main-cover'>
      <Link onClick={handleClick}>
           <span>
                <img src={whatsAppIcon} alt='Astratto whatsApp icon'></img>
            </span> 
      </Link>
    </div>
  )
}

export default WhatsAppStick
