import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { dummyimg, Work_NewIcon, Work_ArchiIcon, Work_Events, Work_FoodIcon, Work_OtherIcon, Work_VidIcon, Work_portIcon } from '../assets/index';
import { Link } from 'react-router-dom';
import WordReveal from './WordRevealEffect';

const OurWorks = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS
  }, []);

  return (
    <>
      <div className='work-main-wrapper-cover'>
        <div className='work-header-title-content-box'>
          <div className='our-work-title'>
            <WordReveal word="Our Works" animationType="fade-up" delay={1400}/>
          </div>
        </div>
        <div className='work-card-main-cover'>
          <div className='word-card-box-cover'>
            <div className='work-card-box card-1' data-aos="fade-right" data-aos-delay="700">
              <Link>
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        New 
                        <br></br>
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_NewIcon} alt="New Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
            <div className='work-card-box card-2' data-aos="fade-right" data-aos-delay="200">

              <Link to='/architecture'>
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        Architecture 
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_ArchiIcon} alt="Architecture Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
            <div className='work-card-box card-3' data-aos="fade-right" data-aos-delay="300">
              <Link to='/food-products'>
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        Products
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_FoodIcon} alt="Food Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
            <div className='work-card-box card-4' data-aos="fade-up" data-aos-delay="600">
             
              <Link to='/videos'>
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        Videos 
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_VidIcon} alt="Video Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
            <div className='work-card-box card-5' data-aos="fade-left" data-aos-delay="300">
              <Link to='/food'>
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        Food 
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_Events} alt="Events Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
            <div className='work-card-box card-6' data-aos="fade-left" data-aos-delay="200">
              <Link to='/portraits'>
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        Portraits
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_portIcon} alt="Portraits Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
            <div className='work-card-box card-7' data-aos="fade-left" data-aos-delay="100">
              <Link to="/lifestyle">
                <div className='work-card-content-box'>
                  <div className='work-card-banner-box'>
                    <div className='work-title'>
                      <p>
                        Others 
                      </p>
                      <div className='work-icon-box'>
                        <img src={Work_OtherIcon} alt="Others Icon" />
                      </div>
                    </div>
                  </div>
                  <div className='hover-effect'></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurWorks;
