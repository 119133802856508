import LightGallery from 'lightgallery/react';
import { archiBanner3 , archiBanner5 } from '../assets';




// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const Gallery = () => {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="light-gallery-main-cover-wrapper">
            <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                <a href="../assets/Architecture/orva-studio-YC8qqp50BdA-unsplash.jpg">
                    <img alt="img1" src={archiBanner3} />
                </a>
                <a href="../assets/Architecture/philip-jahn-fi-8DifJMtM-unsplash.jpg">
                    <img alt="img2" src={archiBanner5} />
                </a>
                
            </LightGallery>
        </div>
    );
}


export default Gallery;