import React from 'react'
import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import OurWorks from '../components/OurWorks'
import WhyChooseUs from '../components/WhyChooseUs'
import WhatWeDo from '../components/WhatWeDo'
import OurPartners from '../components/OurPartners'
import Footer from '../components/Footer'
import LoadingScreen from '../components/LoadingScreen'
import WhatsAppStick from '../components/WhatsAppStick'
import { WHATSAPP_NUMBER } from '../Constants'



const Home = () => {
  return (
    <>
    <div className='main-home'>
      <LoadingScreen/>
      <div className='home-content-main-cover-wrapper'>
        <Header/>
        <HeroSection/>
        <OurWorks/>
        <WhyChooseUs/>
        <WhatWeDo/>
        <OurPartners/>
        <Footer/>
        <WhatsAppStick
          phoneNumber={WHATSAPP_NUMBER}
          message="Hello! I'm interested in your services."
        />
      </div>
    </div>
    </>
  )
}

export default Home
