import React , {useState , useEffect} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Header from '../components/Header';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import app from "../FireBase/firebase"
import MainLoader from '../components/MainLoader';
import { LIFESTYLE_FB_PATH } from '../Constants';
// import { Autoplay  } from 'swiper';
import { Autoplay } from 'swiper/modules';

const LifeStyle = () => {

    const [productsImgData, setProductsImgData] = useState([]);
    const [isImageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            setImageLoading(true)
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, LIFESTYLE_FB_PATH);
                const imageRefs = await listAll(listRef);
                const urls = await Promise.all(
                    imageRefs.items.map((itemRef) => getDownloadURL(itemRef))
                );
                setProductsImgData(urls);
                setImageLoading(false)
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);


    if (isImageLoading) {
        return <MainLoader />;
    }


    return (
        <>
            <Header color="white" />
            <div className="lifestyle-bg-wrapper">

            </div>
            <div className="lifestyle-container">
                <h3 className="lifestyle-h3">Solution</h3>
                <h2 className="lifestyle-h2">
                    We want to help you <br />
                    <span style={{ color: '#d0d0f5' }}>get in touch with your inner self</span> <br />
                    to live your best life
                </h2>
                <p className="lifestyle-p">
                    Our body emanates signals that we can interpret and share in a simple and delightful way. We
                    want to help you get in touch with your inner self to live your best life.
                </p>

                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    // modules={[Pagination]}
                    autoplay={{ // Add autoplay settings
                        delay: 3000, // Delay between transitions (in ms)
                        disableOnInteraction: false, // Allows autoplay to continue after user interactions
                    }}
                    modules={[Autoplay]}
                    loop={true}
                    breakpoints={{
                        340: { slidesPerView: 1, spaceBetween: 10 },
                        768: { slidesPerView: 2, spaceBetween: 15 },
                        1024: { slidesPerView: 5, spaceBetween: 20 },
                    }}
                    className="lifestyle-swiper"
                >
                    {productsImgData && productsImgData.length > 0 && productsImgData.map((productImgUrl, ind) => (
                        <SwiperSlide key={ind} className="lifestyle-swiper-slide mt-5">
                            {/* <img src={image} alt={`Slide ${index + 1}`} /> */}
                            <img src={productImgUrl} alt='food product' />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default LifeStyle



