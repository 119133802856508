import React, { useState, useEffect } from 'react';
import '../styles/custom.css';

const LoadingScreen = () => {
  const [isAfterLoaded, setAfterLoaded] = useState(false);
  const [scale, setScale] = useState(1); // Initial scale

  useEffect(() => {
    const timer = setTimeout(() => {
      setAfterLoaded(true);
    }, 1300);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let start = null;
    const duration = 3500; // Animation duration in milliseconds
    const finalScale = 200; // Final scale value

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const currentScale = Math.min(1 + (finalScale - 1) * (progress / duration), finalScale);
      setScale(currentScale);

      // Continue animation if duration is not reached
      if (progress < duration) {
        requestAnimationFrame(animate);
      }
    };

    // Start the animation
    requestAnimationFrame(animate);

    // Clean up function
    return () => cancelAnimationFrame(animate);
  }, []);

  return (
    <>
      <div 
        id="ajax-loading-screen" 
        data-disable-mobile={1} 
        data-disable-fade-on-click={0} 
        data-effect="horizontal_swipe" 
        data-method="standard" 
        className={`${isAfterLoaded ? 'after-load-effect loaded hidden' : 'loaded hidden'}`}
      >
        <div className="reveal-1">
          <h2 
            style={{
              transform: `scale(${scale})`, // Use transform for scaling
              color: 'white',
              transition: 'transform 3s linear', // Transition for transform
            }}
          >
            ASTRATTO
          </h2>
        </div>
      </div>
    </>
  );
};

export default LoadingScreen;
