// src/ContactForm.js

import React, { useState , useEffect } from 'react';
import Header from './Header';
import { FaPhoneAlt } from "react-icons/fa";
import Footer from "./Footer";
import { FiSend } from "react-icons/fi";
import { contactArrow } from '../assets';
import { contactUsBanner } from '../assets';
import   {Toaster , toast} from "react-hot-toast"
import { validateNumberInput } from '../utils';



const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    message: '',
  });

  const [flash, setFlash] = useState(false);


    const handleCapture = () => {
    setFlash(true); // Show flash
    setTimeout(() => {
      setFlash(false); // Hide flash after 100ms
    }, 100);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  useEffect(() =>{
    window.scrollTo(0,0)
},[])


  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.contactNumber.length > 10 || formData.contactNumber.length < 10){
      return alert("please enter 10 digits mobile number")
    }

    try {
      const response = await fetch('http://localhost:3001/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if(result.status === 200){
        toast.success(result.msg)
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          contactNumber: '',
          message: ''
        })
      }
     
    } catch (error) {
      console.log('Failed to send email. Please try again later.');
    }
  };

  return (
    <>
      <Header />
      <div className='contact-main-cover-wrapper'>
        <div className='contact-content-wrapper mb-0'>
          <div className='container'>
            <div className='row d-flex justify-content-center align-items-center'>
              <div className='col-lg-6'>
                <h3 className='contact-title'>Have questions? <br /> Let's chat and make it happen!</h3>
              </div>
              <div className='col-lg-4'>
                <img className='contact-banner-img' src={contactUsBanner} width={"300px"} ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="row">
            <div className="col-lg-5">
              <div className="contact-info">
                <h2>Contact Information</h2>
                <p className='mb-3'>"Get in touch with us for inquiries, bookings, or collaborations. We’re here to bring your vision to life!"</p>
                <div className='contact-detail-box-cover'>
                  <div className='contact-detail-box'>
                    <div className='contact-icon-box-cover d-flex align-items-center my-3  mx-2'>
                      <div className='contact-icon-box'>
                        <FaPhoneAlt />
                      </div>
                      <p className='mx-3'><strong>Phone:</strong> <br /> +91 9137380337</p>
                    </div>
                    <div className='contact-icon-box-cover d-flex align-items-center my-3 mx-2'>
                      <div className='contact-icon-box'>
                        <FaPhoneAlt />
                      </div>
                      <p className='mx-3'><strong>Phone:</strong> <br /> +91 7907270903</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='contact-form-main-wrapper'>
                <form className="form" onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          placeholder='Enter First Name'
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          placeholder='Enter Last Name'

                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label>Email ID</label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder='Enter Email Id'

                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className="form-group">
                        <label>Mobile No</label>
                        <input
                          type="text"
                          name="contactNumber"
                          value={formData.contactNumber}
                          onChange={handleChange}
                          placeholder='Enter Mobile No'
                          minLength={10}
                          onInput={validateNumberInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      rows={5}
                      name='message'
                      value={formData.message}
                      onChange={handleChange}
                      placeholder='Please describe your inquiry or provide any additional details here...'
                    />
                  </div>

                  <button type="submit" className="submit-button">
                    <span className='mx-2'><FiSend /></span>
                    Send Request</button>
                </form>
                <div className='contact-arrow-cover'>
                  <img src={contactArrow} alt='Astrato Arrow icon'></img>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
      <Footer />
    </>
  );
};

export default ContactForm;
