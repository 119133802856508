import logo from './logo.svg';
import './App.css';
import { BrowserRouter ,   Routes , Route } from 'react-router-dom';
import Home from './pages/Home';
import ContactForm from './components/ContactUs';
import About from './pages/About';
import Header from './components/Header';
import Footer from './components/Footer';
import Architecture from './pages/Architecture';
import Portraits from './pages/Portraits';
import FoodProducts from './pages/FoodProducts';
import Gallery from './components/LightBox';
import Events from './pages/Events';
import Videos from './pages/Videos'
import FoodHome from './pages/Food';
import MovieSwiper from './components/CustomSwiper';
import LifeStyle from './pages/LifeStyle';

function App() {
  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/contact' element={<ContactForm/>}></Route>
          <Route path='/architecture' element={<Architecture/>}></Route>
          <Route path='/portraits' element={<Portraits/>}></Route>
          <Route path='/food-products' element={<FoodProducts/>}></Route>
          <Route path='/gallery' element={<Gallery/>}></Route>
          <Route path='/event' element={<Events/>}></Route>
          <Route path='/videos' element={<Videos/>}></Route>
          <Route path='/food' element={<FoodHome/>}></Route>
          <Route path='/test' element={<MovieSwiper/>}></Route>
          <Route path='/lifestyle' element={<LifeStyle/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
