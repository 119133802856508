import React ,{useEffect , useState , useRef} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import VideoPlayer from '../components/HeroVideoPlayer';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import app from "../FireBase/firebase"
import { VIDEOS_FB_PATH ,HORIZONTAL_VIDEOS_FB_PATH } from '../Constants';
import LazyLoad from 'react-lazyload';
import useInView from '../CustomHooks/useInView';
import MainLoader from '../components/MainLoader';

const Videos = () => {
    const videoRef = useRef(null);
    const inView = useInView({ threshold: 0.5 }); // Adjust threshold as needed
    const [imageURL, setImageURL] = useState("");
    const [imagehorizontalVidUrl, setImagehorizontalVidUrl] = useState("");
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    useEffect(() => {
        const videoElement = videoRef.current;
        
        if (videoElement) {
          if (inView) {
            if (videoElement.paused) {
              videoElement.play().catch(error => {
                console.error('Error attempting to play the video:', error);
              });
            }
          } else {
            if (!videoElement.paused) {
              videoElement.pause();
            }
          }
        }
      }, [inView]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, VIDEOS_FB_PATH);
                const imageRefs = await listAll(listRef);
                const urls = await Promise.all(
                    imageRefs.items.map((itemRef) => getDownloadURL(itemRef))
                );
                setImageURL(urls);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        const fetchVidoes = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, HORIZONTAL_VIDEOS_FB_PATH);
                const imageRefs = await listAll(listRef);
                const urls = await Promise.all(
                    imageRefs.items.map((itemRef) => getDownloadURL(itemRef))
                );
                setImagehorizontalVidUrl(urls);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
        fetchVidoes();
    }, []);

    const handleVideoEnd = () => {
        setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % imagehorizontalVidUrl.length);
      };

    useEffect(() =>{
        console.log(imagehorizontalVidUrl[currentVideoIndex])
    },[imagehorizontalVidUrl])

    return (
        <>
            {(imageURL.length > 0 && imagehorizontalVidUrl.length > 0 ) ? (
                <div className='video-hero-main-section-wrapper py-4'>
                {/* <Header /> */}
                    <div className='video-hero-title-box'>
                        <h5 className='text-center video-hero-title mb-5'>ASTRATTO</h5>
                    </div>
                    <div className='hero-video-content-box-cover'>
                        {imagehorizontalVidUrl[currentVideoIndex] && (
                            <VideoPlayer video={imagehorizontalVidUrl[currentVideoIndex]}
                            playing={true}
                            onEnded={handleVideoEnd} />
                        )}
                    </div>
                    <div className='video-section-cover-wrapper'>
                        <div className='video-section-content-cover-box mb-lg-0 mb-5'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-6 order-lg-0 order-1'>
                                    <div className='video-slider-left-cover-wrapper'>
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={2.5}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >

                                            {imageURL && imageURL.length > 0 && imageURL?.slice(0,9).map((video) => {
                                                    return (
                                                        <SwiperSlide>
                                                            <LazyLoad className='video-container' >
                                                                <video 
                                                                    ref={videoRef}
                                                                    autoPlay="false"
                                                                    loop
                                                                    muted
                                                                    style={{ width: '100%', height: '420px' }}
                                                                >
                                                                    <source src={video} type="video/mp4" />
                                                                </video>
                                                            </LazyLoad>
                                                        </SwiperSlide>
                                                    )
                                                })}
                                            
                                        </Swiper>
                                    </div>
                                </div>
                                <div className='col-lg-5 order-lg-1 order-0'>
                                    <div className='video-right-section-content-wrapper'>
                                        <h5>Story's in Motion</h5>
                                        <div className='video-right-section-cotent-details'>
                                            <p>Discover compelling narratives brought to life through the lens of cinematic excellence. Our videos tell stories that resonate, capturing the essence of human experiences and emotions. With each reel, we invite you to witness the art of storytelling in its most vivid and impactful form.</p>

                                            <p>
                                                A curated selection of videos that capture the essence of dynamic storytelling. This collection showcases the harmony between movement and emotion, where every scene unfolds with purpose and grace. Experience the power of visual storytelling, where every frame is a masterpiece in motion.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='video-section-content-cover-box mb-lg-0 mb-5'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-lg-1'></div>
                                <div className='col-lg-5'>
                                    <div className='video-right-section-content-wrapper video-left-section-content-wrapper'>
                                        <h5>Visual Journeys</h5>
                                        <div className='video-right-section-cotent-details '>
                                            <p>Explore a collection of stunning visual stories that captivate and inspire. Each video is a testament to the art of storytelling, merging striking imagery with powerful narratives. Immerse yourself in a world where every frame is crafted to evoke emotion and leave a lasting impression.</p>
                                            <p>
                                                Dive into immersive videos that take you on a journey through artistry and creativity. From breathtaking landscapes to intimate portraits, each piece invites you to experience the beauty and depth of the world through a unique perspective. Let these visual stories transport you to new realms of imagination.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='video-slider-left-cover-wrapper'>
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={2.5}
                                            onSlideChange={() => console.log('slide change')}
                                            onSwiper={(swiper) => console.log(swiper)}
                                        >
                                            {imageURL && imageURL.length > 0 && imageURL.slice(9 , imageURL.length).map((video) => {
                                                    return (
                                                        <SwiperSlide>
                                                            <LazyLoad className='video-container'>
                                                                <video
                                                                    ref={videoRef}
                                                                    autoPlay="false"
                                                                    loop
                                                                    muted
                                                                    style={{ width: '100%', height: '420px' }}
                                                                >
                                                                    <source src={video} type="video/mp4" />
                                                                </video>
                                                            </LazyLoad>
                                                        </SwiperSlide>
                                                    )
                                                })}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='video-hero-title-box'>
                        <h5 className='text-center video-hero-title'>Our Cinematography</h5>
                    </div>
                    <div className='videos-footer-slider-cover-wrapper p-lg-5 p-2'>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={2}
                            onSlideChange={() => console.log('slide change')}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,  // 1 slide per view on mobile (screen width <= 640px)
                                },
                                768: {
                                    slidesPerView: 2,  // 2 slides per view on tablet and larger (screen width >= 768px)
                                },
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                        >   
                        {imagehorizontalVidUrl && imagehorizontalVidUrl.length > 0 && imagehorizontalVidUrl.map((video) => {
                            return (
                                <SwiperSlide>
                                    <LazyLoad >
                                        <video
                                            ref={videoRef}
                                            autoPlay="false"
                                            loop
                                            muted
                                            style={{ width: '100%', height: 'auto' }}
                                        >
                                            <source src={video} type="video/mp4" />
                                        </video>
                                    </LazyLoad>
                                </SwiperSlide>
                            )
                        })}   
                        </Swiper>
                    </div>
                    {/* <div className=" testimonial-video-main-wrapper">
                            <div className="container">
                                <h1 className="testimonial-video-main-title">Testimonial</h1>
                                <div className="testimonial-video-main-wrapper-cover">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-4">
                                            <div className="testimonial-video-set">
                                                <div className="testimonial-video-1">
                                                    <img src={fp2} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="testimonial-content-video1 make-text-left">
                                                <h3 className>Lorem, ipsum.</h3>
                                                <p className="text-left">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi repudiandae non itaque perferendis vel aliquid rem aut molestiae, optio rerum enim exercitationem esse?</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="testimonial-content-video1 testimonial-content-video2 make-text-right2">
                                                <h3 className>Lorem, ipsum.</h3>
                                                <p className="">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Modi repudiandae non itaque perferendis vel aliquid rem aut molestiae, optio rerum enim exercitationem esse?</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="testimonial-video-set">
                                                <div className="testimonial-video-2">
                                                    <img src={fp2} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div> */}
                </div>
            ) : <MainLoader/> }

        </>
    )
}

export default Videos
