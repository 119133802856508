import React from 'react'

const MainLoader = () => {
  return (
    <div className='astrato-main-loader-cover-wrapper'>
        <section>
        <div className="loading loading07">
            <span data-text="A">A</span>
            <span data-text="S">S</span>
            <span data-text="T">T</span>
            <span data-text="R">R</span>
            <span data-text="A">A</span>
            <span data-text="T">T</span>
            <span data-text="T">T</span>
            <span data-text="O">O</span>
        </div>
        </section>
    </div>
  )
}

export default MainLoader
