import React,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import WordReveal from './WordRevealEffect';

const WhatWeDo = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS
      }, []);
    
  return (
    <div className='what-we-do-main-wrapper-cover'>
        <div class="our-work-title">
            <WordReveal word="What We Do" animationType="fade-up" delay={1400}/>
        </div>
        <div className='what-we-do-card-cover'>
                <div className='row'>
                    
                    <div className='col-lg-4'>
                        <div className='what-we-do-card-cover' data-aos="fade-right" data-aos-delay="400">
                        
                            <div className='what-we-do-card-box'>
                                <h5>Photography</h5>
                                <p>Capturing stunning, high-quality visuals that convey compelling stories and details.</p>
                                <div className='what-we-do-count'>
                                    01
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='what-we-do-card-cover what-card2' data-aos="fade-up" data-aos-delay="700">
                        
                            <div className='what-we-do-card-box'>
                                <h5>Cinematography</h5>
                                <p>Creating engaging and cinematic videos that captivate audiences and highlight key moments.</p>
                                <div className='what-we-do-count'>
                                    02
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='what-we-do-card-cover' data-aos="fade-left" data-aos-delay="400">
                        
                            <div className='what-we-do-card-box'>
                                <h5>Visual Storytelling</h5>
                                <p>Blending photography and video to craft powerful and captivating visual stories.</p>
                                <div className='what-we-do-count'>
                                    03
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>

        </div>
    </div>
  )
}

export default WhatWeDo
