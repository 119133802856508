import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from 'react-router-dom';
import { astrattoLogo } from '../assets';

const Header = ({color}) => {
  return (
    <div className='header-navbar-main-wrapper' >
      <Navbar expand="lg" className="header">
        <Container>
          <Navbar.Brand href="#home">
            <img src={astrattoLogo} alt="Main Logo" className="main-logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav header-navlink-cover">
            <Nav className="header-navlinks">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;