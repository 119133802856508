import React from 'react'
import { circularImg } from '../assets'

const CircularBox = ({color , direction}) => {
    return (
        <>
            <div className={ direction == "left" ? 'move-left circular-box d-flex justify-content-center flex-column' : "move-right circular-box d-flex justify-content-center flex-column"}>
                <h5 className={`${color ? "text-white" : ""}`}>A</h5>
                <img src={circularImg} />
            </div>
        </>
    )
}

export default CircularBox
