import React, { useState, useEffect } from 'react';
import { bathPortrait, port2 } from '../assets';
import CircularBox from '../components/CircularBox';
import { PORTRAIT_FB_PATH } from '../Constants';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import app from "../FireBase/firebase";
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css";
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import MainLoader from '../components/MainLoader';

const Portraits = () => {
    const [imageURLs, setImageURLs] = useState([]);
    const [isImageLoading, setImageLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, PORTRAIT_FB_PATH);
                const imageRefs = await listAll(listRef);
                const urls = await Promise.all(
                    imageRefs.items.map((itemRef) => getDownloadURL(itemRef))
                );
                setImageURLs(urls);
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                // Ensure loading state is updated after fetching
                setTimeout(() =>{
                    setImageLoading(false);
                },500)
            }
        };


        fetchImages();
    }, []);

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    if (isImageLoading) {
        return <MainLoader />;
    }

    return (
        <>
            {/* {isImageLoading && <MainLoader/>} */}
            <div className='portrait-main-cover-wrapper'>
                <div className='container'>
                    <div className='portrait-top-content-box'>
                        <div className='portrait-title-box'>
                            <h5>
                                <span className='highlight1'>Capturing</span>
                                Life's
                                <span className='img-box1'>
                                    <img src={bathPortrait} alt="Astratto bath portrait" />
                                </span>
                                Essence 
                                <span className='img-box1'>
                                    <img src={port2} alt="Astratto portrait" />
                                </span>
                                Through
                                <span className='highlight2'>Portraiture</span>
                            </h5>
                        </div>
                        <p>
                            Photography, the alchemy of capturing souls within frames, immortalizes emotions and stories. Each click of the shutter crystallizes the essence of human experience.
                        </p>
                    </div>
                </div>
            </div>
            <div className='portraits-swiper-cover-wrapper'>
                <div className='portrait-cards-wrapper'>
                    <LightGallery
                        onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                    >
                        {imageURLs.map((img, index) => (
                            <a href={img} className='portrait-card' key={`img_${index}`}>
                                <img alt={`img_${index}`} src={img} loading='lazy' />
                            </a>
                        ))}
                    </LightGallery>
                </div>
            </div>
            <div className='circular-cover-wrapper'>
                <CircularBox color="white" />
            </div>
        </>
    );
};

export default Portraits;
