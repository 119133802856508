import React from 'react'
import { fp1, fp2, fp3, fp4, fp5, port2 } from '../assets'
import { Swiper, SwiperSlide } from 'swiper/react';
import Header from '../components/Header';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {  Autoplay } from 'swiper/modules';

const Events = () => {
    return (
        <>
            <Header/>
            <div className='events-main-cover-wrapper'>
                <div className='event-content-cover-wrapper'>
                    <div className="event-home-main-wrapper">
                        <div className="container">
                            <div className="event-home-main-wrapper-cover">
                                <h1 className="event-home-main-title">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum, ex? Itaque, architecto.</h1>
                                <p className="event-home-main-para">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsam repudiandae omnis perspiciatis quidem enim? Mollitia odio esse voluptate soluta voluptatum error nobis dolore natus sunt.</p>
                            </div>
                            <div className="home-gallery">
                                <div className="row  d-flex justify-content-center align-items-center mt-5">
                                    <div className="col-lg-4">
                                        <div className="event-home-images-gallery1">
                                            <div className="images-gallery1-cover">
                                                <img src={fp1} />
                                            </div>
                                            <div className="images-gallery2-cover">
                                                <img src={port2} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="home-images-gallery2">
                                            <div className="images-gallery3-cover">
                                                <img src={fp3} />
                                            </div>
                                            <div className="images-gallery4-cover">
                                                <img src={fp4} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="home-images-gallery3">
                                            <div className="images-gallery5-cover">
                                                <img src={fp1} />
                                            </div>
                                            <div className="images-gallery6-cover">
                                                <img src={port2} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="event-gallery-main-wrapper">
                        <div className="container-fluid p-0">
                            <div className="event-gallery-cover">
                                <h1 className="gallery-main-title">Our Gallery</h1>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-3">
                                        <div className="event-gallery-set1">
                                            <div className="event-gallery-set1-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className="event-gallery-set2">
                                            <div className="event-gallery-set2-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className="event-gallery-set3">
                                            <div className="event-gallery-set3-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex justify-content-center flex-direction-column align-items-center">
                                        <div className="event-gallery-set4">
                                            <div className="event-gallery-set4-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="event-gallery-set5">
                                            <div className="event-gallery-set5-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className="event-gallery-set6">
                                            <div className="event-gallery-set6-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className="event-gallery-set7">
                                            <div className="event-gallery-set7-slider">
                                                <Swiper loop={true}
                                                    slidesPerView={1}
                                                    autoplay={true}
                                                    modules={[Autoplay]}
                                                    pagination={{ el: '.swiper-pagination' }}>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp1} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp2} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp3} />
                                                    </SwiperSlide>
                                                    <SwiperSlide className='gallery-set1-slider-img'>
                                                        <img src={fp4} />
                                                    </SwiperSlide>
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default Events
