// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCiPoVraCeybIr6kCBLaB0uji8pKMBUe5A",
  authDomain: "astratto-b1568.firebaseapp.com",
  projectId: "astratto-b1568",
  storageBucket: "astratto-b1568.appspot.com",
  messagingSenderId: "699272955419",
  appId: "1:699272955419:web:7fcf42477d9ab8b90f1abe",
  measurementId: "G-8X3LZ1YC85"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const storage = getStorage(app);


export default app