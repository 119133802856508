import React,{useEffect} from 'react'
import { GoNorthStar } from "react-icons/go";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { provenIcon , creativeIcon , qualityIcon } from '../assets';


const WhyChooseUs = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS
      }, []);
  return (
    <div className='why-choose-main-cover-wrapper'>
      <div className='container'>
        <div className="why-choose-inner-cover">
            <div className='row d-flex align-items-center'>
                <div className="col-lg-5">
                    <div className='why-choose-details-content-cover'>
                        <div className="why-chooose-title-cover">
                            <h1 data-aos="fade-up" data-aos-delay="500">Why ASTRATTO ?</h1>
                            <h2 data-aos="fade-right" data-aos-delay="500">Capturing Moments, Creating Memories</h2>
                        </div>
                        <div className='why-choose-detail-box' data-aos="fade-up" data-aos-delay="700">
                            <p>We transform your special moments into lasting visual stories with unmatched creativity and precision, ensuring each shot is a masterpiece you'll cherish forever</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className='why-choose-card-cover'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='why-chooose-inner-card-cover' data-aos="fade-up" data-aos-delay="500">
                                    <div className='why-choose-inner-cover'>
                                        <div className='why-choose-card'>
                                            <div className='why-choose-icon-box mb-2'>
                                                <img src={creativeIcon} width="30px" alt="astratto logo icon"></img>
                                            </div>
                                            <div className='why-choose-card-content-box'>
                                                <h5> Creative Storytelling</h5>
                                                <p>We turn your moments into cinematic experiences with a unique artistic touch.</p>
                                            </div>
                                        </div>
                                        <div className='why-choose-card border-0'>
                                            <div className='why-choose-icon-box mb-2'>
                                                <img src={qualityIcon} width="30px" alt="astratto logo icon"></img>
                                            </div>
                                            <div className='why-choose-card-content-box'>
                                                <h5>Top-Notch Quality</h5>
                                                <p>We use cutting-edge equipment to capture stunning, high-definition visuals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 p-1'>
                                <div className='why-chooose-inner-card-cover border-0' data-aos="fade-down" data-aos-delay="800">
                                    <div className='why-choose-inner-cover2'>
                                        <div className='why-choose-card'>
                                            <div className='why-choose-icon-box mb-2'>
                                                <img src={provenIcon} width="30px" alt="astratto logo icon"></img>
                                            </div>
                                            <div className='why-choose-card-content-box'>
                                                <h5>Proven Expertise:</h5>
                                                <p>Years of experience in diverse settings ensure consistently excellent results.</p>
                                            </div>
                                        </div>
                                        <div className='why-choose-card border-0'>
                                            <div className='why-choose-icon-box mb-2'>
                                                <GoNorthStar/>
                                            </div>
                                            <div className='why-choose-card-content-box'>
                                                <h5>Tailored Service:</h5>
                                                <p>We personalize every project to match your vision, making each experience unforgettable.</p>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>    
        </div>  
      </div>
    </div>
  )
}

export default WhyChooseUs
