import React, { useEffect, useRef, useState } from 'react'
import CircularBox from '../components/CircularBox';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import app from "../FireBase/firebase"
import { FOOD_FB_PATH } from '../Constants';
import LightGallery from 'lightgallery/react';
import MainLoader from '../components/MainLoader';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css"
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

const FoodHome = () => {

    const wrapper1Ref = useRef(null);
    const wrapper2Ref = useRef(null);
    const [productsImgData, setProductsImgData] = useState([]);
    const [isImageLoading, setImageLoading] = useState(true)


    useEffect(() => {
        const fetchImages = async () => {
            setImageLoading(true)
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, FOOD_FB_PATH);
                const imageRefs = await listAll(listRef);
                const urls = await Promise.all(
                    imageRefs.items.map((itemRef) => getDownloadURL(itemRef))
                );
                setProductsImgData(urls);
                setImageLoading(false)
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
 

    if (isImageLoading) {
        return <MainLoader />;
    }


    return (
        <div className='food-cover'>
            <div className='food-main-cover-wrapper'>
                <div className='food-main-content-cover-wrapper'>
                    <div className='container'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-lg-6 col-8'>
                                <div className='food-content-details-cover-wrapper'>
                                    <div className='food-content-title'>
                                        <h5>Deliciously Photogenic:  Art of Food Products Photography</h5>
                                        <p>In the realm of food products photography, every shot is an opportunity to transform a simple dish into a captivating visual story. The art of capturing food in its most photogenic form requires a keen eye for detail, an understanding of lighting and composition, and a touch of creativity. Whether you're working with vibrant produce.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 ml-3 d-lg-flex d-none'>
                                {isImageLoading ? <h5></h5> : (
                                    <div className='vertcal-food-scroll-main-cover-wrapper1' ref={wrapper1Ref}>
                                        <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                                            {productsImgData && productsImgData.length > 0 && productsImgData.map((productImgUrl, ind) => (
                                                <a data-src={productImgUrl} className='verical-food-scroll-card' key={`wrapper1_${ind}`}>
                                                    <img src={productImgUrl} alt='food product' loading='lazy' />
                                                </a>
                                            ))}
                                        </LightGallery>
                                    </div>
                                )}
                            </div>
                            <div className='col-lg-3 p-0 col-4'>
                                {isImageLoading ? <h5></h5> : (
                                    <div className='vertcal-food-scroll-main-cover-wrapper2' ref={wrapper2Ref}>
                                        <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
                                            {productsImgData && productsImgData.length > 0 && productsImgData.map((productImgUrl, ind) => (
                                                <a data-src={productImgUrl} className='verical-food-scroll-card' key={`wrapper2_${ind}`}>
                                                    <img src={productImgUrl} alt='food product' loading='lazy' />
                                                </a>
                                            ))}
                                        </LightGallery>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='circular-cover-wrapper mx-2 '>
                <CircularBox color="white" direction="left" />
            </div>
        </div>
    )
}



export default FoodHome
