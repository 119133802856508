import React, { useState, useEffect } from 'react'
import "../styles/custom.css"
import CircularBox from '../components/CircularBox';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import app from "../FireBase/firebase"
import Header from '../components/Header';
import { ARCHITECTURE_FB_PATH, slideContent } from '../Constants';
import MainLoader from '../components/MainLoader';

const Architecture = () => {
    const [isPaused, setIsPaused] = useState(false);
    const [imageURL, setImageURL] = useState("");
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isDataLoading , setDataLoading] = useState(true) 

    const handleMouseEnter = () => {
        setIsPaused(true);
    };

    const handleMouseLeave = () => {
        setIsPaused(false);
    };

    // Update the current slide index on slide change
    const handleSlideChange = (swiper) => {
        if (swiper && swiper.initialized) {
            setCurrentSlide(swiper.realIndex);
            console.log(swiper);
        }
    };

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, ARCHITECTURE_FB_PATH);
                const imageRefs = await listAll(listRef);
                const urls = await Promise.all(
                    imageRefs.items.map((itemRef) => getDownloadURL(itemRef))
                );
                setTimeout(() =>{
                    setDataLoading(false);
                },800)
                setImageURL(urls);
            } catch (error) {
                console.error('Error fetching images:', error);
                setDataLoading(false);
            }
        };
        fetchImages();
    }, []);


    return (
        <>
            {isDataLoading ? <MainLoader /> : (
                <>
                    <Header />
                    <div className='arhcitecture-main-cover-wrapper'>
                        <div className='architecture-inner-cover-wrapper'>
                            <Swiper
                                spaceBetween={30}
                                effect={'fade'}
                                // navigation={true}
                                autoplay={true}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[EffectFade, Autoplay]}
                                onSlideChange={handleSlideChange}
                                className="mySwiper architecture-main-content-cover"
                            >

                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[0]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[0]} />
                                </SwiperSlide>

                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[1]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[1]} />
                                </SwiperSlide>
                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[2]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[2]} />
                                </SwiperSlide>
                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[3]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[3]} />
                                </SwiperSlide>
                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[4]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[4]} />
                                </SwiperSlide>
                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[5]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[5]} />
                                </SwiperSlide>
                                <SwiperSlide key={`swiper-slide-img-box_${imageURL[6]}`}>
                                    <img className='swiper-slide-img-box' src={imageURL[6]} />
                                </SwiperSlide>

                                <div className='vertical-marquee-main-wrapper'
                                >
                                    {imageURL?.length > 0 && imageURL?.slice().reverse().map((sliderImg, ind) => {
                                        return (
                                            <div className='architecture-card-box-cover'
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave} style={{ animationPlayState: isPaused ? 'paused' : 'running' }}>
                                                <div className='architecture-card-box'>
                                                    <img src={sliderImg} alt='architecture'></img>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className='circular-cover-wrapper'>
                                    <CircularBox />
                                </div>
                                <div className='info-content-bx'>
                                    <div className='info-title-box'>
                                        <h2>Seaside Serenity Villa</h2>
                                    </div>
                                    <p>
                                        Experience the tranquility of oceanfront living with our exclusive seaside villas, where luxury meets the beauty of nature.
                                    </p>
                                </div>
                            </Swiper>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Architecture
