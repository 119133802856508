import VideoPlayer from './HeroVideoPlayer'
import { dummyVideo2, dummyVideo3, dummyVideo4, starIcon , homeIndexVid1 , homeIndexVid2 , homeIndexVid3 , homeIndexVid4 , vertHomeIndex1} from '../assets'
import "swiper/swiper-bundle.css";
import "swiper/css";


const HeroSection = () => {


  return (
    <>
      <div className='Hero-section-main-wrapper'>
        <div className='hero-heading-title-box'>
          <div className='star-icon-box'>
            <img src={starIcon} alt="astratto star icon"></img>
          </div>
          <h3 className='text-center'> <span className='highlight-title'>Storytelling</span>  Through the <span className='highlight-title'> Lens</span> </h3>
          <div className='star-icon-box sm-icon'>
            <img src={starIcon} alt="astratto star icon"></img>
          </div>
        </div>
        <div className='hero-content-main-cover-wrapper'>
          <div className='hero-custom-design-cover-wrapper'>
            <div className='row d-flex justify-content-center align-items-center'>
              <div className='col-lg-2 col-2'>
                <div className='hero-horizon1-video-box hero-small-atck-vid'>
                  <VideoPlayer video={homeIndexVid4} playing={true} />
                </div>
              </div>
              <div className='col-lg-2 col-2'>
                <div className='horizontal-stack1-video-box'>
                  <div className='hero-horizon1-video-box'>
                    <VideoPlayer video={homeIndexVid1} playing={true} />
                  </div>
                  <div className='hero-horizon1-video-box'>
                    <VideoPlayer video={homeIndexVid2} playing={true} />
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-3'>
                <div className='hero-vertical-video-box'>
                  <VideoPlayer playing={true} video={vertHomeIndex1} />
                </div>
              </div>
              <div className='col-lg-2 col-2'>
                <div className='horizontal-stack2-video-box'>
                  <div className='hero-horizon1-video-box'>
                    <VideoPlayer video={homeIndexVid3} playing={true} />
                  </div>
                  <div className='hero-horizon1-video-box'>
                    <VideoPlayer video={homeIndexVid4} playing={true} />
                  </div>
                </div>
              </div>
              <div className='col-lg-2 col-2'>
                <div className='hero-horizon1-video-box hero-small-atck-vid2'>
                  <VideoPlayer video={dummyVideo4} playing={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
