import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
// import './customswiper.css'; // Ensure this path is correct for your project

const MovieSwiper = () => {
  const movies = [
    {
      title: "Doctor Strange",
      image: "https://i.pinimg.com/736x/6e/e1/42/6ee14205af61438867a41ea3968564b4.jpg",
      description: "Dr. Stephen Strange's life changes after a car accident robs him of the use of his hands."
    },
    {
      title: "Avengers: Endgame",
      image: "https://i.pinimg.com/564x/d1/28/08/d1280812fe674d149c8f2a5ccee3a541.jpg",
      description: "After the devastating events of Avengers: Infinity War, the universe is in ruins."
    },
    {
      title: "Guardians of the Galaxy",
      image: "https://i.pinimg.com/564x/59/17/b3/5917b3a1286508193a4f751585e47466.jpg",
      description: "A group of intergalactic criminals must pull together to stop a fanatical warrior with plans to purge the universe."
    },
    {
      title: "Doctor Strange",
      image: "https://i.pinimg.com/736x/6e/e1/42/6ee14205af61438867a41ea3968564b4.jpg",
      description: "Dr. Stephen Strange's life changes after a car accident robs him of the use of his hands."
    },
    {
      title: "Avengers: Endgame",
      image: "https://i.pinimg.com/564x/d1/28/08/d1280812fe674d149c8f2a5ccee3a541.jpg",
      description: "After the devastating events of Avengers: Infinity War, the universe is in ruins."
    }
  ];

  return (
    <div className='swiper-main-cover-wrapper'>
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        centeredSlides={true}
        loop={true}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 3,
          slideShadows: true
        }}
      >
        {movies.map((movie, index) => (
          <SwiperSlide key={index}>
            <div className="swiper-slide-content" style={{ backgroundImage: `url(${movie.image})` }}>
              <div className="overlay"></div>
              <div className="movie-details">
                <h3>{movie.title}</h3>
                <p>{movie.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MovieSwiper;
