import { dummyVideo , dummyVideo2 , dummyVideo3 , dummyVideo4 } from "./assets"
export const WHATSAPP_NUMBER = "+919137380337"
export const INSTA_ID = ""
export const PORTRAIT_FB_PATH = "portraits/"
export const ARCHITECTURE_FB_PATH = "archtecture/"
export const PRODUCTS_FB_PATH = "products/"
export const LIFESTYLE_FB_PATH = "lifestyle/"
export const FOOD_FB_PATH = "food/"
export const VIDEOS_FB_PATH = "videos/"
export const HORIZONTAL_VIDEOS_FB_PATH = "horizontalVideos/"


export const slideContent = [
    {
        title: 'Whimsical Woods Villa',
        description: 'Step into a world of unparalleled elegance and design with our collection of luxury villas in India. Each property is meticulously crafted to perfection.',
    },
    {
        title: 'Seaside Serenity Villa',
        description: 'Experience the tranquility of oceanfront living with our exclusive seaside villas, where luxury meets the beauty of nature.',
    },
    {
        title: 'Mountain Majesty Villa',
        description: 'Escape to the mountains with our luxurious mountain villas, offering breathtaking views and unparalleled comfort.',
    },
    {
        title: 'Urban Chic Villa',
        description: 'Discover the epitome of modern living in the heart of the city with our stylish urban villas, designed for the discerning homeowner.',
    },
    {
        title: 'Urban Chic Villa',
        description: 'Discover the epitome of modern living in the heart of the city with our stylish urban villas, designed for the discerning homeowner.',
    },
    {
        title: 'Whimsical Woods Villa',
        description: 'Step into a world of unparalleled elegance and design with our collection of luxury villas in India. Each property is meticulously crafted to perfection.',
    },
    {
        title: 'Seaside Serenity Villa',
        description: 'Experience the tranquility of oceanfront living with our exclusive seaside villas, where luxury meets the beauty of nature.',
    },
    {
        title: 'Mountain Majesty Villa',
        description: 'Escape to the mountains with our luxurious mountain villas, offering breathtaking views and unparalleled comfort.',
    }
];
