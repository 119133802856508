import React from 'react'
import { partner1, itcHotelLogo, qMinLogo, tajLogo, novoHotelLogo, hyattLogo, hiltonLogo, leelaLogo, LalitMumbaiLogo, partnerFSeasonLogo, courtyardLogo, jwMariotLogo, welcomeHotelLogo, leftTransparentbg, rightTransparentbg } from '../assets';
import Marquee from 'react-fast-marquee';
import WordReveal from './WordRevealEffect';

const OurPartners = () => {
    return (
        <div className='our-partner-main-cover-wrapper'>
            <div class="our-work-title">
                <WordReveal word="Our Partners" animationType="fade-up" delay={1400} />
            </div>
            <div className='our-partner-swiper-wrapper'>
                <div className='slider-main-wrapper'>
                    <Marquee gradient="black">
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={partnerFSeasonLogo} alt="Partner F Season Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={LalitMumbaiLogo} alt="Lalit Mumbai Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={jwMariotLogo} alt="JW Marriott Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={leelaLogo} alt="Leela Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={courtyardLogo} alt="Courtyard Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={welcomeHotelLogo} alt="Welcome Hotel Logo" />
                        </div>
                    </Marquee>
                    <Marquee gradient="black" direction='right' className='mt-4'>

                        <div className='partner-card-box'>
                            <img className=' w-75' src={itcHotelLogo} alt="ITC Hotel Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className=' w-75' src={qMinLogo} alt="Q Min Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={tajLogo} alt="Taj Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={novoHotelLogo} alt="Novo Hotel Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={hiltonLogo} alt="Hilton Logo" />
                        </div>
                        <div className='partner-card-box'>
                            <img className='partner-logo' src={hyattLogo} alt="Hyatt Logo" />
                        </div>

                    </Marquee>
                </div>
            </div>
        </div>
    )
}

export default OurPartners
