import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { IoLogoInstagram } from "react-icons/io5";
import { ImFacebook2 } from "react-icons/im";
import { IoLogoYoutube } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { astrattoLogo } from '../assets';
import { WHATSAPP_NUMBER } from '../Constants';


const Footer = () => {
  
    const handleClick = () => {
        const url = `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${encodeURIComponent("Hello ")}`;
        window.open(url, '_blank');
      };
    return (
        <div className='footer-main-cover-wrapper'>
            <div className='footer-content-cover-box'>
                <div className='container'>
                    <div className='footer-top-content-box'>
                        <div className='footer-values-section-box'>
                            <div className='values-header-content-box d-flex'>

                                <div className='footer-values-title-box ml-5'>
                                    <h5 className='text-center'>Capturing the essence of values that define our true selves in every shot.</h5>
                                    <h5 className='text-center'>Dedicated to authenticity and integrity, ensuring every photograph reflects our commitment to excellence. </h5>
                                </div>
                            </div>

                            <div className='footer-contact-card'>
                                <div className='footer-title-content'>
                                    <h6>Ready for a simple future</h6>
                                    <h6 className='add-color'>Lets get Started</h6>
                                </div>

                                <Link to="/contact">
                                    Get Started
                                </Link>
                            </div>

                            <div className='footer-strip-box-cover'>
                                <div className='row d-flex '>
                                    <div className='col-lg-3'>
                                        <div className='footer-logo-box'>
                                            <img src={astrattoLogo} alt="Main Logo" className="main-logo"></img>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='footer-link-box'>
                                            <div className='footer-link-title'>
                                                <h5>Company</h5>
                                            </div>
                                            <ul>
                                                <li>
                                                    <Link to="/about"> About Us</Link>
                                                </li>

                                                <li>
                                                    <Link to="/contact"> Contact Us</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='footer-link-box'>
                                            <div className='footer-link-title'>
                                                <h5>Further Information</h5>
                                            </div>
                                            <ul>
                                                <li>
                                                    <NavLink> Terms & Conditions</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink> Privacy Policy </NavLink>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className='social-link-box'>
                                            <div className='footer-link-title'>
                                                <h5>You can connect us on :</h5>
                                            </div>
                                            <div className='social-card-box'>
                                                <ul>
                                                    <li>
                                                        <Link to="https://www.instagram.com/astratto_stories?igsh=azJ6d3poZ2gyZzZo">
                                                            <IoLogoInstagram />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link onClick={handleClick}>
                                                            <BsWhatsapp />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                    </div>
                </div>
                <div className='powered-main-wrapper'>
                    <h6>Powered by <Link to="https://maheebportfolio.vercel.app/">Maheeb Khan</Link></h6>
                </div>
            </div>
        </div>
    )
}

export default Footer
